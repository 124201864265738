<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="xl"
      hide-footer
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">ค้นหาลูกค้า</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <div class="filter-panel">
          <InputText
            v-model="filter.search"
            textFloat="ค้นหา"
            type="text"
            name="search_customer"
            faIcon="search"
            className="mb-2"
            @onEnter="handleSearch"
            @onClickIcon="handleSearch"
          />
          <div class="mb-1">
            <b-form-checkbox
              v-model="filter.isRedeem"
              :value="1"
              :unchecked-value="0"
              @change="handleCheckedIsRedeem"
            >
              ลูกค้าที่ยังไม่ได้แลกของสมนาคุณ
            </b-form-checkbox>
            <b-form-checkbox
              v-model="filter.currentOrAll"
              :value="this.branchId"
              :unchecked-value="0"
              @change="handleChecked"
            >
              แสดงเฉพาะสาขา {{ branchName }}
            </b-form-checkbox>
          </div>
        </div>
        <b-row>
          <b-col class="d-none d-md-block">
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(select)="data">
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hover"
                  @click="selectRow(data.item)"
                  :disabled="isBusy"
                >
                  เลือก
                </b-button>
              </template>
              <template v-slot:cell(member_id)="data">
                <p>{{ data.item.member_id || "-" }}</p>
              </template>
              <template v-slot:cell(member_tier)="data">
                <p>{{ data.item.member_tier || "-" }}</p>
              </template>
              <template v-slot:cell(first_name_th)="data">
                <p>{{ data.item.first_name_th || "-" }}</p>
              </template>
              <template v-slot:cell(last_name_th)="data">
                <p>{{ data.item.last_name_th || "-" }}</p>
              </template>
              <template v-slot:cell(point)="data">
                <div>{{ data.item.point | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(center_point)="data">
                <div>{{ data.item.center_point | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div v-if="data.item.created_time != '0001-01-01T00:00:00'">
                  {{
                    $moment(data.item.created_time)
                      .add(543, "years")
                      .format($formatDateNewFull)
                  }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="@/assets/images/icons/note.png"
                    alt="transfer"
                    class="action-img pointer"
                    width="20"
                    center
                    @click="$refs.modalNote.show(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col class="d-block d-md-none">
            <div class="py-2 detail-panel mb-5 mt-2">
              <div
                class="px-2 text-sub text-grey d-flex justify-content-between"
              >
                <span>สมาชิก / ชื่อ / โทร</span>
                <span>ระดับ / คะแนนสะสม / วันที่สมัคร</span>
              </div>
              <div
                class="p-2 m-2 bg-white pointer"
                v-for="(c, index) in items"
                :key="index"
                @click="selectRow(c)"
              >
                <div class="d-flex justify-content-between">
                  <p>{{ c.member_tier }}</p>
                  <p>
                    <label>{{ c.first_name_th }} {{ c.last_name_th }}</label>
                  </p>
                </div>
                <div class="d-flex justify-content-between">
                  <p>{{ c.member_id }}</p>
                  <p>{{ c.point || c.center_point }} คะแนน</p>
                </div>
                <div class="d-flex justify-content-between">
                  <p>{{ c.telephone }}</p>
                  <p>{{ new Date(c.created_time) | moment($formatDate) }}</p>
                </div>
              </div>
              <div
                class="p-2 m-2 bg-white no-data-item"
                v-if="items.length == 0"
              >
                ไม่พบข้อมูล
              </div>
            </div>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </b-modal>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import OtherLoading from "@/components/loading/OtherLoading";
import Pagination from "@/components/Pagination";
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";
import ModalNote from "@/components/customer/ModalNote";

export default {
  props: {
    redeemId: {
      type: [String, Number],
      default: 0,
    },
  },
  components: {
    InputTextArea,
    OtherLoading,
    Pagination,
    InputText,
    ModalNote,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      filter: {
        page: 1,
        take: 10,
        search: "",
        branchId: 0,
        isRedeem: 0,
        redeemId: 0,
        currentOrAll: 0,
      },
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "first_name_th",
          label: "ชื่อ",
          tdClass: "text-left min-w-100",
        },
        {
          key: "last_name_th",
          label: "นามสกุล",
          tdClass: "text-left min-w-100",
        },
        {
          key: "point",
          label: "คะแนนสาขา",
          tdClass: "min-w-150",
        },
        {
          key: "center_point",
          label: "คะแนนสะสม",
          tdClass: "min-w-150",
        },
        {
          key: "telephone",
          label: "เบอร์โทร",
        },
        {
          key: "member_id",
          label: "Member ID",
        },
        {
          key: "member_tier",
          label: "Tier",
        },
        {
          key: "created_time",
          label: "วันที่สมัคร",
        },
        {
          key: "action",
          label: "",
          tdClass: "text-center",
        },
      ],
      now: null,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      isBusy: false,
      rows: 0,
    };
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      branchName: "getBranchName",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  methods: {
    getList: async function () {
      await this.$store.dispatch("setMainLoading", true);
      this.isBusy = true;
      this.filter.redeemId = this.redeemId;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.branch_id = this.branchId;
      payload.redeem_id = payload.redeemId;
      payload.is_redeem = payload.isRedeem;
      payload.current_or_all = payload.currentOrAll;

      this.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/redeem/search_user_redeem_by_name`, payload)
        .then(async (data) => {
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoading = false;
          }
          await this.$store.dispatch("setMainLoading", false);
        });
      this.isBusy = false;
    },
    async getCustomers(search) {
      this.filter.page = 1;
      this.filter.search = search;
      this.filter.branchId = this.branchId;
      this.filter.currentOrAll = this.branchId;
      await this.pagination(1);
      return this.items;
    },
    async show(search) {
      this.filter.page = 1;
      this.filter.search = search;
      this.filter.branchId = this.branchId;
      this.filter.currentOrAll = this.branchId;
      await this.pagination(1);
      if (this.rows == 1) {
        await this.setUserSelect(this.items[0]);
      } else if (this.rows == 0) {
        this.warningAlert("ไม่พบข้อมูลลูกค้า");
      } else {
        this.showModal = true;
      }
    },
    async autoSelect(search) {
      this.filter.search = search;
      await this.pagination(1);
      if (this.rows == 1) {
        await this.setUserSelect(this.items[0]);
      } else if (this.rows == 0) {
        this.warningAlert("ไม่พบข้อมูลลูกค้า");
      } else {
        this.showModal = true;
      }
    },
    hide() {
      this.showModal = false;
    },
    async getNote(userGuid) {
      this.isBusy = true;
      await this.axios
        .get(`${this.$baseUrl}/redeem/get_note/${userGuid}`)
        .then((data) => {
          if (data.result == 1) {
            this.form.note = data.note || "";
          }
        });
      this.isBusy = false;
    },
    async pagination(page) {
      this.filter.page = page;
      await this.getList();
    },
    async handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      await this.getList();
    },
    async clearFilter() {
      this.filter.page = 1;
      this.filter.take = 10;
      await this.getList();
    },
    async handleSearch(search) {
      this.filter.search = search;
      await this.pagination(1);
    },
    async selectRow(item) {
      await this.setUserSelect(item);
      await this.hide();
    },
    setUserSelect(item) {
      this.$emit("setUserSelect", item);
    },
    handleChecked(val) {
      this.pagination(1);
    },
    handleCheckedIsRedeem(val) {
      this.pagination(1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.table-main {
  min-height: 360px;
}

::v-deep .div-input {
  width: 50%;
}

.filter-panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;

      .modal-body {
        overflow-y: auto;
      }
    }
  }

  .filter-panel {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
</style>
