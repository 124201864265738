<template>
  <div class="d-none">
    <b-modal v-model="showModal" centered hide-header hide-footer>
      <template #modal-header="{ close }">
        <div class="modal-title">ยืนยันตัวตนด้วยวิธีอื่น</div>
      </template>

      <div class="text-black">
        ต้องการยืนยันตัวตนคุณ {{ selectedItem.name }} ด้วยวิธีอื่นหรือไม่
        (ทางแอดมินจะยืนยันอีกครั้ง)
      </div>

      <div class="d-flex justify-content-between w-100 my-0">
        <b-button
          variant="close"
          class="btn-modal btn-hover w-50 mr-1"
          :disabled="disabled"
          @click="hide"
        >
          ยกเลิก
        </b-button>
        <b-button
          variant="primary-color"
          class="btn-modal btn-hover w-50 ml-1"
          :disabled="disabled"
          @click="conformWithoutOTP"
        >
          ยืนยัน
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showModal: false,
      disabled: false,
      selectedItem: {
        redeem_order_id: 0,
        user_id: 0,
        user_guid: '',
        product_type: 0,
        redeem_name: '',
        redeem_id: 0,
        imge: '',
        image_url: null,
        image_path: null,
        gift: '',
        product_name: null,
        member_id: '',
        name: '',
        first_name_th: '',
        last_name_th: '',
        telephone: '',
        used_point: 0,
        point_used: 0,
        branch_id: 0,
        branch_name: '',
        redeem_date: '',
        status: 0,
        redeem_skip: 0,
        ready_valid: 0,
        cancel_valid: 0,
        status_name: '',
        note: null,
        redeem_item_id: 0,
        description: null,
        is_theme: 0,
        theme_primary_color: null,
        theme_secondary_color: null,
        theme_icon_color: null,
        theme_background: null,
        theme_background_page: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId',
      userGuid: 'getUserGuid'
    })
  },
  methods: {
    show(item) {
      this.selectedItem = { ...item };
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async conformWithoutOTP() {
      let oldStatus = this.selectedItem.status;
      if (oldStatus == 3) {
        let body = {
          new_status: 4,
          redeem_order_id: this.selectedItem.redeem_order_id,
          user_id: this.userGuid
        };
        await this.axios
          .post(`${this.$baseUrl}/redeem/update_redeem_order_status`, body)
          .then(async data => {
            if (data.result == 1) {
              this.disabled = true;
              this.successAlert('เปลียนสถานะเสร็จสิ้น');
              this.$emit('success');
              this.hide();
            } else {
              this.warningAlert('ไม่สามารถเปลียนสถานะได้');
            }
          })
          .catch(err => {
            this.warningAlert(err.message);
          });
      } else {
        if (oldStatus == 0) this.warningAlert('ไม่มีรายการแลกของสมนาคุณนี้');
        else this.warningAlert('ไม่ได้อยู่ในสถานะสินค้าพร้อมรับ');
      }
    }
  }
};
</script>

<style scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  color: var(--primary-color);
  padding: 15px !important;
}
.btn-outline {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

@media (max-width: 768px) {
  ::v-deep .modal-title {
    text-align: center;
    width: 100%;
  }
}
</style>
