<template>
  <div class="d-none">
    <b-modal v-model="showModal" centered hide-header hide-footer>
      <div class="modal-header">
        <h4 class="mb-0">ยกเลิกการแลกของสมนาคุณ</h4>
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <div v-if="product">
        คุณต้องการยกเลิกของสมนาคุณ {{ product.product_name }} ของคุณ
        {{ customer.first_name_th }}
        {{ customer.last_name_th }} โดยระบบจะทำการคืนคะแนนให้
        {{ product.used_point | numeral("0,0") }} คะแนนหรือไม่
      </div>
      <div class="mt-3">
        <b-button
          class="btn-modal radius-btn"
          block
          variant="secondary"
          @click="confirm"
          >ยืนยัน</b-button
        >
        <b-button
          class="btn-modal radius-btn"
          block
          variant="outline"
          @click="hide"
          >ยกเลิก</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ 
      branchId: "getBranchId",
      userGuid: "getUserGuid" 
    }),
  },
  data() {
    return {
      showModal: false,
      product: null,
      customer: null,
    };
  },
  methods: {
    show(product, customer) {
      this.product = product;
      this.customer = customer;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async confirm() {
      let oldStatus = this.product.status
      if(oldStatus == 1) {
        let body = {
          new_status: 6,
          redeem_order_id: this.product.redeem_order_id,
          user_id: this.userGuid
        };
        await this.$store.dispatch("setMainLoading", true);
        await this.axios
          .post(`${this.$baseUrl}/redeem/update_redeem_order_status`, body)
          .then(async (data) => {
            this.$store.dispatch("setMainLoading", false);
            if (data.result == 1) {
              this.successAlert("ยกเลิกรายการเสร็จสิ้น");
              this.$emit("success");
              this.hide();
            } else {
              this.warningAlert("ไม่สามารถเปลียนสถานะได้");
            }
          })
          .catch((err) => {
            this.warningAlert(err.message);
          });
      } else {
        if(oldStatus == 0) this.warningAlert("ไม่มีรายการแลกของสมนาคุณนี้");
        else if(oldStatus == 5) this.warningAlert("ลูกค้าท่านนี้ได้รับของสมนาคุณไปแล้ว");
        else if(oldStatus == 6) this.warningAlert("ยกเลิกรายการแลกของสมนาคุณนี้แล้ว");
        else this.warningAlert("ไม่สามารถยกเลิกรายการนี้ได้");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  border-bottom: 0 !important;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}

::v-deep .modal-body {
  color: var(--primary-color);
  padding: 15px !important;
}
.btn-outline {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}
</style>
